import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import PropTypes from "prop-types";
import Prices from "./Prices";
import {FacebookEmbed} from 'react-social-media-embed';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import FacebookIcon from "@mui/icons-material/Facebook";
import {Helmet} from "react-helmet";

function Testimonials(props) {
    const {title, getFeed, feed, setFeed, setCurrentRoute} = props;

    const feedPosts = React.useMemo(() => (getFeed()), [getFeed]);

    React.useEffect(()=> {
        setCurrentRoute("success");

        feedPosts.then((res) => {
            if (res) {
                setFeed(res)
            }
        })
    }, [feedPosts, setCurrentRoute, setFeed])
    return (
        <Grid
            item
            xs={12}
            md={8}
            sx={{
                '& .markdown': {
                    py: 3,
                },
            }}
        >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Success Stories</title>
                <meta name="description" content="See our latest success stories and reviews from facebook. We have 100\'s of prior students that have passed using RightMark" />
            </Helmet>
            <Typography variant="h2" gutterBottom>
                {title}
            </Typography>
            <Paper key='reviews'>
                <Box sx={{m: 5, paddingBottom: 2, paddingTop: 2}}>
                    <Grid>
                        <Link
                            display="block"
                            variant="body1"
                            href="https://www.facebook.com/RightMarkDrivingSchool"
                            key="Facebook"
                            sx={{mb: 0.5}}
                        >
                            <Stack direction="row" spacing={1} alignItems="center">
                                <FacebookIcon/>
                                <span>See our reviews on Facebook</span>
                            </Stack>
                        </Link>
                    </Grid>
                </Box>
            </Paper>
            <Divider/>
            {feed.map((item) => (
                   <Paper key={item}>
                       <Box sx={{m: 5, paddingBottom: 2, paddingTop: 2}}>
                           <Grid>
                               <div style={{display: 'flex', justifyContent: 'center'}}>
                                   <FacebookEmbed
                                       url={item}
                                       width={'100%'}/>
                               </div>
                           </Grid>
                       </Box>
                   </Paper>
            ) )}
        </Grid>);
}

Prices.propTypes = {
    title: PropTypes.string.isRequired,
    post: PropTypes.array
};

export default Testimonials;
