import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Markdown from "../Markdown";
import {Helmet} from "react-helmet";

function Booking(props) {
    const {posts, title} = props;
    React.useEffect(()=> {
        props.setCurrentRoute("booking");
    })
    return (
        <Grid
            item
            xs={12}
            md={8}
            sx={{
                '& .markdown': {
                    py: 3,
                },
            }}
        >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Booking Your Tests</title>
                <meta name="description" content="Be ready on the day you book your driving test. Here is all information you need before and after you book your driving test." />
            </Helmet>
            <Typography variant="h2" gutterBottom>
                {title}
            </Typography>
            <Divider/>
            {posts.map((text) => (
                <Markdown className="markdown" text={text} key={text.substring(0, 40)}/>
            ))}
        </Grid>
    );
}

Booking.propTypes = {
    posts: PropTypes.arrayOf(PropTypes.string).isRequired,
    title: PropTypes.string.isRequired,
};

export default Booking;
