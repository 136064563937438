import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';

function FeaturedDeal(props) {
  const { deal } = props;

  return (
    <Grid item xs={12} md={6}>
      <CardActionArea component="a" href="/contact">
        <Card sx={{ display: 'flex' }}>
          <CardContent sx={{ flex: 1 }}>
            <Typography component="h2" variant="h4">
              {deal.title}
            </Typography>
            <Typography variant="h3" color="text.secondary">
              Only {deal.price}
            </Typography>
            <Typography variant="subtitle1" paragraph>
              {deal.description}
            </Typography>
          </CardContent>
        </Card>
      </CardActionArea>
    </Grid>
  );
}

FeaturedDeal.propTypes = {
  deal: PropTypes.shape({
    description: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default FeaturedDeal;
