import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

function Sidebar(props) {
    const {useful, description, social, title} = props;

    return (
        <Grid item xs={12} md={4}>
            <Paper elevation={0} sx={{p: 2, bgcolor: 'grey.200'}}>
                <Typography variant="h2" gutterBottom>
                    {title}
                </Typography>
                <Typography>{description}</Typography>
            </Paper>
            <Typography variant="h3" gutterBottom sx={{mt: 3}}>
                Useful Links
            </Typography>
            {useful.map((useful) => (
                <Stack direction="row" spacing={1} alignItems="center" key={'stack-' + useful.title}>
                    <Link display="block" variant="h6" href={useful.url} key={useful.title} gutterBottom
                          sx={{mt: 1}} underline="hover">
                        {useful.title}
                    </Link>
                </Stack>
            ))}

            <Typography variant="h4" gutterBottom sx={{mt: 3}}>
                Social
            </Typography>
            {social.map((network) => (
                <Link
                    display="block"
                    variant="h6"
                    href={network.link}
                    key={network.name}
                    sx={{mb: 1}}
                >
                    <Stack direction="row" spacing={1} alignItems="center">
                        <network.icon/>
                        <span>{network.name}</span>
                    </Stack>
                </Link>
            ))}
        </Grid>
    );
}

Sidebar.propTypes = {
    useful: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }),
    ).isRequired,
    description: PropTypes.string.isRequired,
    social: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.elementType.isRequired,
            link: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }),
    ).isRequired,
    title: PropTypes.string.isRequired,
};

export default Sidebar;
