import * as React from 'react';
import './Header.css';
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import logo from "./content/imgs/RMLogo.webp";
import Grid from "@mui/material/Grid";

function Header(props) {
    const {sections, title} = props;

    return (
        <React.Fragment>
            <Toolbar sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Grid container spacing={2} textAlign="center" align="center" sx={{paddingTop: 5, paddingBottom: 5}}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <img src={logo} align="left" className="App-logo" alt="driving lessons telford"/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={4}>
                        <Typography
                            component="h1"
                            variant="h3"
                            color="inherit"
                            align="center"
                            sx={{flex: 1}}
                        >
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Button variant="contained" size="large" href={'/contact'} >
                            Contact
                        </Button>
                    </Grid>
                </Grid>

            </Toolbar>
            <Toolbar
                component="nav"
                variant="dense"
                sx={{justifyContent: 'space-between', overflowX: 'auto'}}
            >
                {sections.map((section) => (
                    <Link
                        color="inherit"
                        noWrap
                        key={section.title}
                        variant="body2"
                        href={section.url}
                        sx={{p: 1, flexShrink: 0}}
                    >
                        {section.title}
                    </Link>
                ))}
            </Toolbar>
        </React.Fragment>
    )
        ;
}

Header.propTypes = {
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }),
    ).isRequired,
    title: PropTypes.string.isRequired,
};

export default Header;
