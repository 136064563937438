import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Markdown from "../Markdown";
import {Helmet} from "react-helmet";

function Prices(props) {
    const {title, posts} = props;
    React.useEffect(()=> {
        props.setCurrentRoute("prices");
    })
    return (
        <Grid
            item
            xs={12}
            md={8}
            sx={{
                '& .markdown': {
                    py: 3,
                },
            }}
        >
            <Helmet>
                <meta charSet="utf-8" />
                <title>RightMark - Prices</title>
                <meta name="description" content="Choose from package booking deal or the Pay-As-You-Go rate. Or get in touch for a custom deal tailored to your individual requirements." />
            </Helmet>
            <Typography variant="h3" gutterBottom>
                {title}
            </Typography>
            <Divider/>
            {posts.map((text) => (
                <Markdown className="markdown" text={text} key={text.substring(0, 40)}/>
            ))}
        </Grid>
    );
}

Prices.propTypes = {
    title: PropTypes.string.isRequired,
};

export default Prices;
