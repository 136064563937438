import * as React from 'react';
import './Main.css'
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Markdown from '../Markdown';
import {Helmet} from "react-helmet";

function Main(props) {
    const {posts, title} = props;
    React.useEffect(() => {
        props.setCurrentRoute("main");
    })


    return (
        <Grid
            item
            xs={12}
            md={8}
            sx={{
                img: {
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    paddingBottom: 2,
                },
                '& .markdown': {
                    py: 3,
                },
            }}
        >
            <Helmet>
                <meta charSet="utf-8"/>
                <title>RightMark - Home</title>
                <meta name="description" content="Driving lessons for manual cars in Telford. Learn to drive using a great instructor with a commitment to teaching you how to drive safely and confidently"/>
            </Helmet>
            <Typography variant="h3" gutterBottom>
                {title}
            </Typography>
            <Divider/>
            <Grid>
                {posts.map((text) => (
                    <Markdown className="markdown" text={text} key={text.substring(0, 40)}/>
                ))}
            </Grid>
        </Grid>
    );
}

Main.propTypes = {
    posts: PropTypes.arrayOf(PropTypes.string).isRequired,
    title: PropTypes.string.isRequired,
};

export default Main;
