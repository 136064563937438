import * as React from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

function MarkdownListItem(file) {
    return <Box component="li" sx={{mt: 1, typography: 'body1'}} {...file} />;
}

const options = {
    overrides: {
        h1: {
            component: Typography,
            props: {
                gutterBottom: true,
                variant: 'h4',
                component: 'h1',
            },
        },
        h2: {
            component: Typography,
            props: {gutterBottom: true, variant: 'h6', component: 'h2'},
        },
        h3: {
            component: Typography,
            props: {gutterBottom: true, variant: 'subtitle1'},
        },
        h4: {
            component: Typography,
            props: {
                gutterBottom: true,
                variant: 'caption',
                paragraph: true,
            },
        },
        p: {
            component: Typography,
            props: {paragraph: true},
        },
        a: {component: Link},
        li: {
            component: MarkdownListItem,
        },
    },
};

export default function Markdown(state) {
    return <Paper>
        <Box sx={{m: 5, paddingBottom: 2, paddingTop: 2}}>
            <Grid>
                <ReactMarkdown options={options} >{state.text}</ReactMarkdown>
            </Grid>
        </Box>

    </Paper>;
}
