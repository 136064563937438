import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import FacebookIcon from "@mui/icons-material/Facebook";
import {Helmet} from "react-helmet";

function Contact(props) {
    const {title} = props;
    React.useEffect(()=> {
        props.setCurrentRoute("contact");
    })
    return (
        <Grid
            item
            xs={12}
            md={8}
            sx={{
                '& .markdown': {
                    py: 3,
                },
            }}
        >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact RightMark</title>
                <meta name="description" content="Ready to get behind the wheel and learn to drive in telford? Contact RightMark by Phone, Email or Facebook" />
            </Helmet>
            <Typography variant="h2" gutterBottom>
                {title}
            </Typography>
            <Divider/>
            <Paper key='contact-hours'>
                <Box sx={{m: 5, paddingBottom: 2, paddingTop: 2}}>
                    <h2>Office Hours:</h2>
                    <h2>Mon - Fri from 07:00 to 16:30 </h2>
                </Box>
            </Paper>
            <Paper key='contact-telephone'>
                <Box sx={{m: 5, paddingBottom: 2, paddingTop: 2}}>
                    <h2>Call or send a text to: </h2>
                    <h2><a href="tel:07505147415">07505147415</a></h2>
                </Box>
            </Paper>
            <Paper key='contact-email'>
                <Box sx={{m: 5, paddingBottom: 2, paddingTop: 2, overflow: 'hidden', wordBreak: 'break-all'}}>
                    <h2>Send an email to: </h2>
                    <h2><a href="mailto:markymarkbag@hotmail.co.uk">markymarkbag@hotmail.co.uk</a></h2>
                </Box>
            </Paper>
            <Paper key='contact-facebook'>
                <Box sx={{m: 5, paddingBottom: 2, paddingTop: 2}}>
                    <Grid>
                        <Link
                            display="block"
                            variant="body1"
                            href="https://www.facebook.com/RightMarkDrivingSchool"
                            key="Facebook"
                            sx={{mb: 0.5}}
                        >
                            <Stack direction="row" spacing={1} alignItems="center">
                                <FacebookIcon/>
                                <h2>Message Us On Facebook</h2>
                            </Stack>
                        </Link>
                    </Grid>
                </Box>
            </Paper>
        </Grid>
    );
}

Contact.propTypes = {
    title: PropTypes.string.isRequired,
};

export default Contact;
