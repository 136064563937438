import * as React from 'react';
import {initializeApp} from "firebase/app";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
// import YouTubeIcon from '@mui/icons-material/YouTube';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Sidebar from './Sidebar';
import Footer from './Footer';

import Main from './pages/Main';
import Testimonials from './pages/Testimonials';
import Prices from "./pages/Prices";
import Booking from "./pages/Booking";
import Learn from "./pages/Learn";
import Contact from "./pages/Contact";

import Apley from './content/imgs/ApleyTelford.webp'
import DS3Full from './content/imgs/DS3Full.webp'
import DS3Front from './content/imgs/DS3Front.webp'
import DS3Rear from './content/imgs/DS3Rear.webp'

import pas1 from './content/imgs/passed/pass1.webp'
import pas2 from './content/imgs/passed/pass2.webp'
import pas3 from './content/imgs/passed/pass3.webp'
import pas4 from './content/imgs/passed/pass4.webp'
import pas5 from './content/imgs/passed/pass5.webp'
import pas6 from './content/imgs/passed/pass6.webp'

import main1 from './content/main-page1.md';
import main2 from './content/main-page2.md';
import main3 from './content/main-page3.md';
import main4 from './content/main-page4.md';

import prices1 from './content/price-page.md'

import learn1 from './content/learning-page1.md'
import learn2 from './content/learning-page2.md'
import learn3 from './content/learning-page3.md'
import learn4 from './content/learning-page4.md'
import learn5 from './content/learning-page5.md'

import book1 from './content/booking-page1.md'
import book2 from './content/booking-page2.md'
import book3 from './content/booking-page3.md'
import book4 from './content/booking-page4.md'
import book5 from './content/booking-page5.md'
import book6 from './content/booking-page6.md'

import {useState, useEffect, useMemo} from "react";
import FeaturedDeal from "./FeaturedDeal";
import axios from "axios";

const firebaseConfig = {
    apiKey: "AIzaSyCUTUtMR01vk-eCUphdnd5DUSuOzkbR6cY",
    authDomain: "rightmark2022.firebaseapp.com",
    projectId: "rightmark2022",
    storageBucket: "rightmark2022.appspot.com",
    messagingSenderId: "568420038734",
    appId: "1:568420038734:web:d8a491c8123fb069f5eb1f",
    measurementId: "G-EZXL963VBK"
};

initializeApp(firebaseConfig);

const passImage = () => {
    const images = [pas1, pas2, pas3, pas4, pas5, pas6]
    const pick = Math.floor(Math.random() * 6);
    return images[pick]
}

const getFeed = async () => {
    let link = []
    const accessToken = "EAAL3x9diqqoBANGgflRlXNKzxYzriOSTg5GIvidyNf40OOU1uc0qoBi1aEyoIQjo1nipLLXl52D2PlBZBiexlCw6QLBZALNDxZAZCTvfb4dOZCPDY3WXzylfPV2plPrKOvOuNDYb8tQ9FxbI8hVm6actFhUXH3WWpZC12EhL1ZBih5g7HbwJ0pF";
    await axios({
        url:
            "https://graph.facebook.com/730542496956334/feed?fields=permalink_url&limit=6&access_token=" + accessToken,
        method: "get",
    }).then((data) => {
        data.data.data.forEach((item) => link.push(item.permalink_url))
        console.log(link)
        return link
    }).catch((error) => {
        console.log(error)
        return []
    })
}


const sections = [
    {title: 'Home', url: '/'},
    {title: 'Latest Success Stories', url: '/success'},
    {title: 'Prices', url: '/prices'},
    {title: 'Learning To Drive', url: '/learn'},
    {title: 'Booking Your Test', url: '/booking'},
    {title: 'Contact', url: '/contact'},
];

const mainFeaturedPost = {
    title: 'Driving Lessons Telford with RightMark',
    description:
        "Put the Right Mark on your driving skills today",
    image: Apley,
    imageText: 'Driving Lessons Telford',
    linkText: 'Get in touch',
};

const featuredPosts = [
    {
        title: 'What car will you drive?',
        description:
            'A 2018 plate, Three Door, Manual Citroen DS3.',
        link: '#what-will-you-drive',
        image: DS3Full,
        imageLabel: 'Citroen DS3',
    },
    {
        title: 'Join the 100\'s of other students that have passed with me',
        description: '',
        link: 'success',
        image: passImage(),
        imageLabel: 'Passed Student',
    },
];

const featuredDeals = [
    // {
    //     title: 'Pay As You Go - Hourly',
    //     price: '£36 p/h',
    //     description:
    //         'We understand some people can not commit to a block of time for a multitude of reasons ' +
    //         'so for that we offer a Pay-As-You-Go rate',
    // },
    {
        title: '10 Hour Block Deal - £365',
        price: '£36.50 p/h',
        description:
            'If you are able to commit to a block of hours with us we offer you a discounted hourly rate',
    },
    {
        title: '20 Hour Block Deal - £710',
        price: '£35.50 p/h',
        description:
            'If you are able to commit to a larger block of hours with us we can offer you a' +
            ' greater discounted hourly rate',
    },
    {
        title: 'Motorway - 2 Hours - £75',
        price: '£40 p/h',
        description:
            'For those that need experience driving on the motorway' +
            ' we offer a focused lesson to build your confidence'
    },
    {
        title: 'Refresher - 2 Hours - £75',
        price: '£40 p/h',
        description:
            'When you have prior experience on the road and just need to brush up on a few things before going in for the test'
    },
    {
        title: 'Refresher - 5 Hours - £192.50',
        price: '£38.50 p/h',
        description:
            'If it has been as while since you were last on the road and you need a comprehensive skill check before going in for the test'
    },
    {
        title: 'Assessment - 2 Hours - £80',
        price: '£40 p/h',
        description:
            'Think you are ready for the practical test but want a practice run first? Try this one-off assessment to test your skills'
    },
];

const sidebar = {
    title: 'About',
    description:
        'Manual driving lessons in Telford are not cheap, and it can be difficult to find quality instruction at an ' +
        'affordable price. Fortunately, RightMark offers some of the best manual driving lessons in Telford for those who ' +
        'want to learn how to drive safely and confidently. ',
    useful: [
        {title: 'Apply for a provisional licence', url: 'https://www.gov.uk/apply-first-provisional-driving-licence'},
        {title: 'Book the theory test', url: 'https://www.gov.uk/book-theory-test'},
        {title: 'Book the practical test', url: 'https://www.gov.uk/book-driving-test'},
        {
            title: 'Show Me, Tell Me Questions',
            url: 'https://www.gov.uk/government/publications/car-show-me-tell-me-vehicle-safety-questions'
        },
        {title: 'The Highway Code', url: 'https://www.gov.uk/browse/driving/highway-code-road-safety'},
        {
            title: 'Android Mobile - Driving Theory',
            url: 'https://play.google.com/store/apps/details?id=uk.co.focusmm.DTSCombo&hl=en_GB&gl=US&pli=1'
        },
        {
            title: 'Apple - Official DVSA Theory',
            url: 'https://apps.apple.com/gb/app/official-dvsa-theory-test-kit/id463295925'
        },
        {
            title: 'Udemy - UK Driving Theory Test',
            url: 'https://www.udemy.com/course/pass-the-uk-driving-theory-test'
        },
        {
            title: 'Apply for a replacement licence',
            url: 'https://www.gov.uk/apply-online-to-replace-a-driving-licence'
        },
        {
            title: 'Exchange paper licence',
            url: 'https://www.gov.uk/exchange-paper-driving-licence'
        },
        {
            title: 'Rearrange your theory test',
            url: 'https://www.gov.uk/change-theory-test'
        },
        {
            title: 'Rearrange your practical test',
            url: 'https://www.gov.uk/change-driving-test'
        },
    ],
    social: [
        // {name: 'YouTube', link: 'https://en-gb.facebook.com/RightMarkDrivingSchool', icon: YouTubeIcon},
        {name: 'Facebook', link: 'https://en-gb.facebook.com/RightMarkDrivingSchool', icon: FacebookIcon},
        {name: 'GitHub', link: 'https://github.com/leedward', icon: GitHubIcon},
    ],
};

const theme = createTheme({
    palette: {
        type: 'standard',
        primary: {
            main: 'rgb(0,0,0)',
            light: '#ffffff',
            dark: '#504747',
            paper: 'background.paper',
        },
        secondary: {
            main: '#f3df01',
        },
        background: {
            paper: '#fff',
            headers: '#8e8585',
            default: 'rgb(246,243,219)',
        },
        divider: 'rgba(0, 0, 0, 0.12)',
    },
});

export default function App() {

    const getTextFromFile = (file) => {
        return fetch(file)
            .then(res => res.text())
            .then(res => {
                return res;
            })
    }

    const [currentRoute, setCurrentRoute] = useState("")

    const [MainPage, setMainPage] = useState([])
    const mainPosts = useMemo(() => ([main1, main2, main3, main4]), []);

    const [PricePage, setPricePage] = useState([])
    const pricesPosts = useMemo(() => ([prices1]), []);

    const [LearnPage, setLearnPage] = useState([])
    const learnPosts = useMemo(() => ([learn1, learn2, learn3, learn4, learn5]), []);

    const [BookPage, setBookPage] = useState([])
    const bookPosts = useMemo(() => ([book1, book2, book3, book4, book5, book6]), []);

    const [feed, setFeed] = useState([
        'https://www.facebook.com/615587320571140/posts/611645440965328',
        'https://www.facebook.com/615587320571140/posts/611641617632377',
        'https://www.facebook.com/615587320571140/posts/582493020547237',
        'https://www.facebook.com/615587320571140/posts/576496457813560',
        'https://www.facebook.com/615587320571140/posts/576452147817991',
        'https://www.facebook.com/615587320571140/posts/575423047920901'])
    // 'https://www.facebook.com/615587320571140/posts/533456432117563',
    // 'https://www.facebook.com/615587320571140/posts/504094305053776',
    // 'https://www.facebook.com/615587320571140/posts/502451331884740',
    // 'https://www.facebook.com/615587320571140/posts/493136462816227'

    // const feedPosts = useMemo(() => (getFeed()), []);

    useEffect(() => {
        mainPosts.forEach((post) => getTextFromFile(post)
            .then(res =>
                setMainPage(text => [...text, res])))

        pricesPosts.forEach(post => getTextFromFile(post)
            .then(res =>
                setPricePage(text => [...text, res])))

        learnPosts.forEach(post => getTextFromFile(post)
            .then(res =>
                setLearnPage(text => [...text, res])))
        bookPosts.forEach(post => getTextFromFile(post)
            .then(res =>
                setBookPage(text => [...text, res])))
    }, [mainPosts, pricesPosts, learnPosts, bookPosts])

    const mainFeature = (post) => {
        if (currentRoute === "main") {
            return <MainFeaturedPost post={post}/>
        }
    }
    const secondaryFeatures = () => {
        if (currentRoute === "main") {
            return <Grid container spacing={4}>
                {featuredPosts.map((post) => (
                    <FeaturedPost key={post.title} post={post}/>
                ))}
            </Grid>
        }
        if (currentRoute === "prices") {
            return <Grid container spacing={4} sx={{mb: 2}}>
                {featuredDeals.map((deal) => (
                    <FeaturedDeal key={deal.title} deal={deal}/>
                ))}
            </Grid>
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Container maxWidth="lg">
                <Header title="RightMark Driving School Telford" sections={sections}/>
                <main>
                    {mainFeature(mainFeaturedPost)}
                    {secondaryFeatures(featuredPosts)}
                    <Grid container spacing={5} sx={{mt: 3}}>
                        <BrowserRouter id="browser-router">
                            <Routes id="route-all">
                                <Route id="route-default" exact path="*"
                                       element={<Main title="About RightMark" posts={MainPage}
                                                      setCurrentRoute={setCurrentRoute}/>}/>
                                <Route id="route-home" exact path="/"
                                       element={<Main title="About RightMark" posts={MainPage}
                                                      setCurrentRoute={setCurrentRoute}/>}/>
                                <Route id="route-testimonials" exact path="/success"
                                       element={<Testimonials title="Latest Success Stories"
                                                              feed={feed} setFeed={setFeed} getFeed={getFeed}
                                                              setCurrentRoute={setCurrentRoute}/>}/>
                                <Route id="route-prices" exact path="/prices"
                                       element={<Prices title="Prices and Packages that Fit You"
                                                        posts={PricePage}
                                                        setCurrentRoute={setCurrentRoute}/>}/>
                                <Route id="route-learn" exact path="/learn"
                                       element={<Learn title="Where to Start?" posts={LearnPage}
                                                       setCurrentRoute={setCurrentRoute}/>}/>
                                <Route id="route-booking" exact path="/booking"
                                       element={<Booking title="Thinking about Booking your Driving Test?"
                                                         posts={BookPage} setCurrentRoute={setCurrentRoute}/>}/>
                                <Route id="route-contact" exact path="/contact"
                                       element={<Contact title="Get in Touch"
                                                         setCurrentRoute={setCurrentRoute}/>}/>
                            </Routes>
                        </BrowserRouter>
                        <Sidebar
                            title={sidebar.title}
                            description={sidebar.description}
                            useful={sidebar.useful}
                            social={sidebar.social}
                        />
                    </Grid>
                </main>
            </Container>
            <Footer
                title="RightMark Driving School"
                description="Put the Right Mark on your driving, book your lessons in Telford today!"
            />
        </ThemeProvider>
    );
}
